import React, { useContext } from "react";
import { UserOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { AuthContext } from "./../Provider/AuthProvider";
import { LoadContext } from "./../Provider/LoadProvider";

const LogCont = styled.div`
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Login = () => {
  const navigate = useNavigate();
  const { setLoginState } = useContext(AuthContext);
  const { setIsLoading, notif } = useContext(LoadContext);

  const login = () => {
    try {
      setIsLoading(true);
      setLoginState("verified");
      navigate("/admin/dashboard");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notif("error", error.message || error);
    }
  };

  const Lbtn = styled(Button)`
    width: 216px;
  `;

  return (
    <LogCont>
      <div>
        <h2>Agent Panel</h2>
      </div>
      <Space direction="vertical">
        <Input placeholder="email" prefix={<UserOutlined />} />
        <Input.Password placeholder="password" prefix={<UserOutlined />} />
        <Lbtn onClick={() => login()} type="primary">
          Login
        </Lbtn>
      </Space>
    </LogCont>
  );
};

export default Login;
