import React from "react";
import Login from "./Page/Login";
import Router from "./Page/Router";
import RouterProvider from "./Provider/RouterProvider";
import { AuthProvider } from "./Provider/AuthProvider";
import { LoadProvider } from "./Provider/LoadProvider";
import { ConfigProvider } from "antd";
import LayoutPage from "./Component/LayoutPage";
import { AuthContext } from "./Provider/AuthProvider";

const Main = () => {
  return (
    <RouterProvider>
      <AuthProvider>
        <LoadProvider>
          <ConfigProvider>
            <Router />
          </ConfigProvider>
        </LoadProvider>
      </AuthProvider>
    </RouterProvider>
  );
};

export default Main;
