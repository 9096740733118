import { Button, Checkbox, Form, Input, Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import styled from "styled-components";
const PageDrawer = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const userInp = styled(Input)``;

  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        Add New User
      </Button>
      <Drawer maskStyle={{ backdropFilter: "blur(3px)" }} title="add new user" placement="right" onClose={onClose} open={open}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="name"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input style={{ width: "180px", marginLeft: "15px" }} placeholder="name" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input style={{ width: "180px", marginLeft: "15px" }} placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="verify Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input style={{ width: "180px", marginLeft: "15px" }} placeholder="verify Password" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              add user
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default PageDrawer;
