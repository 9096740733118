import { Layout, Space } from "antd";
import { Route, Routes, Navigate } from "react-router";
import { Card } from "antd";
import Dashboard from "./../Page/Dashboard";
import Login from "./../Page/Login";
import Ikev from "./../Page/Protocols/Ikev";
import Ipsec from "./../Page/Protocols/Ipsec";
import Wireguard from "./../Page/Protocols/Wireguard";
import Openvpn from "./../Page/Protocols/Openvpn";
import Users from "./../Page/Users";
import Billing from "./../Page/Billing";
import Logs from "./../Page/Logs";
import SubAgent from "./../Page/Reseller/SubAgent";
import TransferHistory from "./../Page/Reseller/TransferHistory";
import Servers from "./../Page/Servers";
import styled from "styled-components";
import ContCart from "./ContCart";
import SideBar from "./SideBar";
import LogOut from "../Page/LogOut";
import PageContent from "./PageContent";
import { RouteItem } from "./Route.Item";

const { Header, Footer, Sider, Content } = Layout;
// const headerStyle = {
//   textAlign: "center",
//   color: "#000",
//   height: 64,
//   paddingInline: 50,
//   lineHeight: "64px",
//   backgroundColor: "#7dbcea",
// };
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  height: "100%",
  color: "#fff",
  backgroundColor: "#108ee9",
};
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#3ba0e9",
};

const LayHead = styled(Header)`
  text-align: center;
  background-color: white !important;
  height: 64;
  line-height: "64px";
  & p {
    font-size: large;
    font-weight: 500;
    margin: 0px;
  }
`;

const LaySide = styled(Sider)`
  text-align: "center";
  background-color: white !important;
`;

const LayCont = styled(Content)`
  height: auto;
`;

const LayoutPage = () => {
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        height: "100%",
      }}
      size={[0, 48]}
    >
      <Layout>
        <LayHead>
          <p>Agent Panel</p>
        </LayHead>
        <Layout>
          <LaySide>
            <SideBar />
          </LaySide>
          <LayCont>
            <Card
              bordered={false}
              style={{
                border: "none",
                margin: "30px",
                textAlign: "left",
                padding: "0px",
              }}
            >
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/protocols/ikev2" element={<Ikev />} />
                <Route path="/protocols/ipsec" element={<Ipsec />} />
                <Route path="/protocols/wireguard" element={<Wireguard />} />
                <Route path="/protocols/openvpn" element={<Openvpn />} />
                <Route path="/users" element={<Users />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/reseller/sub" element={<SubAgent />} />
                <Route path="/reseller/transfer" element={<TransferHistory />} />
                <Route path="/servers" element={<Servers />} />
                <Route path="/logout" element={<LogOut />} />
                {/* {RouteItem.map((route, idx) => {
                  return <Route path={route.path} key={idx} element={route.element} name={route.name} />;
                })} */}
                {/* <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} /> */}
              </Routes>
            </Card>
          </LayCont>
        </Layout>
      </Layout>
    </Space>
  );
};
export default LayoutPage;
