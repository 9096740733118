import React from "react";

const Servers = () => {
  return (
    <div>
      <p>servers list</p>
      <p>sorry. you dont have any persoanl server on system</p>
    </div>
  );
};

export default Servers;
