import { useState } from "react";
import { AudioOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Space, Button, Card, Table } from "antd";
import styled from "styled-components";
const { Search } = Input;

const onSearch = (value) => console.log(value);
const Billing = () => {
  const [size, setSize] = useState("default");

  const columns = [
    {
      title: "index",
      width: 50,
      dataIndex: "index",
      key: "name",
      fixed: "left",
    },
    {
      title: "amount(days)",
      width: 50,
      dataIndex: "amount",
      key: "register",
    },
    {
      title: "type",
      width: 60,
      dataIndex: "type",
      key: "1",
    },
    {
      title: "note",
      width: 60,
      dataIndex: "note",
      key: "2",
    },
    {
      title: "date",
      width: 100,
      dataIndex: "date",
      key: "3",
    },
  ];
  const data = [
    {
      key: "1",
      index: "1",
      amount: "300",
      type: "admin charge",
      note: "admin charge 300",
      date: "22/12/21 - 11:45:05",
    },
    {
      key: "2",
      index: "2",
      amount: "30",
      type: "admin charge",
      note: "admin charge 30",
      date: "22/12/21 - 11:45:05",
    },
    {
      key: "3",
      index: "3",
      amount: "30",
      type: "admin charge",
      note: "admin charge 30",
      date: "22/12/21 - 11:45:05",
    },
    {
      key: "4",
      index: "4",
      amount: "100",
      type: "admin charge",
      note: "admin charge 100",
      date: "22/12/21 - 11:45:05",
    },
    {
      key: "5",
      index: "5",
      amount: "50",
      type: "admin charge",
      note: "admin charge 50",
      date: "22/12/21 - 11:45:05",
    },
  ];

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const TopD = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: -17px;
  `;
  return (
    <div className="site-card-border-less-wrapper">
      <TopD>
        <p>billing list : 5</p>
      </TopD>
      <Card style={{ border: "none" }}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            y: 285,
          }}
          bordered
        />
      </Card>
    </div>
  );
};

export default Billing;
