import React, { useState, useEffect, createContext } from "react";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [loginState, setLoginState] = useState(null);

  useEffect(() => {
    Auth();
  }, []);

  const Auth = () => {
    try {
      setLoginState("verified");
    } catch (error) {
      setLoginState("rejected");
    }
  };

  const contextValue = {
    loginState: loginState,
    setLoginState: setLoginState,
  };

  return <AuthContext.Provider value={contextValue}>{!loginState ? null : children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
