import { Card } from "antd";
import "./../App.css";
import styled from "styled-components";
const gridStyle = {
  width: "100%",
  textAlign: "left",
};

const Dashboard = () => (
  <Card
    style={{
      border: "none",
      padding: "0px",
    }}
    title="Agent Info"
  >
    <Card.Grid style={gridStyle}>Email Address : </Card.Grid>
    <Card.Grid style={gridStyle}>Current Balance : (days)</Card.Grid>
    <Card.Grid style={gridStyle}>Account Count : </Card.Grid>
    <Card.Grid style={gridStyle}>Active Account Count : </Card.Grid>
    <Card.Grid style={gridStyle}>
      <Card title="IKev2 guide">
        <Card.Grid style={gridStyle}>how to create (or remove) accounts : </Card.Grid>
        <Card.Grid style={gridStyle}>active on iOS devices : (comming soon)</Card.Grid>
        <Card.Grid style={gridStyle}>active on android devices : (comming soon)</Card.Grid>
      </Card>
    </Card.Grid>
    <Card.Grid style={gridStyle}>
      <Card title="Ipsec guide">
        <Card.Grid style={gridStyle}>how to create (or remove) accounts : </Card.Grid>
        <Card.Grid style={gridStyle}>active on iOS devices : </Card.Grid>
        <Card.Grid style={gridStyle}>active on android devices : </Card.Grid>
      </Card>
    </Card.Grid>
    <Card.Grid style={gridStyle}>
      <Card title="Wireguard guide">
        <Card.Grid style={gridStyle}>how to create (or remove) accounts : </Card.Grid>
        <Card.Grid style={gridStyle}>active on iOS devices : (comming soon)</Card.Grid>
        <Card.Grid style={gridStyle}>active on android devices : (comming soon)</Card.Grid>
        <Card.Grid style={gridStyle}>android client : </Card.Grid>
        <Card.Grid style={gridStyle}>iOS client : </Card.Grid>
        <Card.Grid style={gridStyle}>Mac OS client : </Card.Grid>
        <Card.Grid style={gridStyle}>Windows client : </Card.Grid>
      </Card>
    </Card.Grid>
    <Card.Grid style={gridStyle}>
      <Card title="openvpn guide">
        <Card.Grid style={gridStyle}>active on iOS devices : (comming soon)</Card.Grid>
        <Card.Grid style={gridStyle}>active on android devices : (comming soon)</Card.Grid>
        <Card.Grid style={gridStyle}>android client : </Card.Grid>
        <Card.Grid style={gridStyle}>iOS client : </Card.Grid>
        <Card.Grid style={gridStyle}>Mac OS client : </Card.Grid>
        <Card.Grid style={gridStyle}>Windows client : </Card.Grid>
      </Card>
    </Card.Grid>
  </Card>
);

export default Dashboard;
