import React, { createContext, useState } from "react";
import { notification, Spin } from "antd";

const LoadContext = createContext();
const LoadProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const notif = (type, title, placement, description) => {
    notification[type]({
      message: title,
      description: description,
      placement: placement || "topRight",
    });
  };

  const contextValue = {
    notif: notif,
    setIsLoading: setIsLoading,
  };

  return (
    <LoadContext.Provider value={contextValue}>
      {!isLoading ? null : (
        <div>
          <Spin size="large" tip="Loading..." />
        </div>
      )}
      {children}
    </LoadContext.Provider>
  );
};

export { LoadContext, LoadProvider };
