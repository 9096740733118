import {
  DashboardOutlined,
  PoweroffOutlined,
  CloudServerOutlined,
  BranchesOutlined,
  SolutionOutlined,
  UserOutlined,
  CreditCardOutlined,
  SettingOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { Menu } from "antd";

const array = [
  { key: "1", label: "Dashboard", icon: <DashboardOutlined />, url: "dashboard" },
  {
    key: "2",
    label: "Protocols",
    icon: <SettingOutlined />,
    url: "protocols",
    children: [
      {
        key: "3",
        label: "ikev2",
        icon: <SettingOutlined />,
        url: "ikev2",
        parent: "protocols",
      },
      {
        key: "4",
        label: "IPsec",
        icon: <SettingOutlined />,
        url: "ipsec",
        parent: "protocols",
      },
      {
        key: "5",
        label: "Wireguard",
        icon: <SettingOutlined />,
        url: "wireguard",
        parent: "protocols",
      },
      {
        key: "6",
        label: "Openvpn",
        icon: <SettingOutlined />,
        url: "openvpn",
        parent: "protocols",
      },
    ],
  },
  { key: "7", label: "Users", icon: <UserOutlined />, url: "users" },
  { key: "8", label: "Billing", icon: <CreditCardOutlined />, url: "billing" },
  { key: "9", label: "Logs", icon: <SolutionOutlined />, url: "logs" },
  {
    key: "10",
    label: "Reseler",
    icon: <BranchesOutlined />,
    url: "reseller",
    children: [
      {
        key: "11",
        label: "Sub Agents",
        icon: <BranchesOutlined />,
        url: "sub",
        parent: "reseller",
      },
      {
        key: "12",
        label: "Transfer History",
        icon: <BranchesOutlined />,
        url: "transfer",
        parent: "reseller",
      },
    ],
  },
  { key: "13", label: "Servers", icon: <CloudServerOutlined />, url: "servers" },
  { key: "14", label: "Log Out", icon: <PoweroffOutlined />, url: "logout" },
];

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SideBar = () => {
  let navigate = useNavigate();
  const onClick = (e, b, c) => {
    console.log("click ", e);

    const element = array.find((_) => e?.key === _?.key);
    if (element) {
      navigate(`/admin/${element?.url}`);
      return;
    }

    const childrenElement = array
      .map((_) => {
        if (_.children?.length) {
          return _.children;
        }
        return [];
      })
      .flat();

    const url = childrenElement.find((_) => e?.key === _?.key)?.url;
    const parentUrl = childrenElement.find((_) => e?.key === _?.key).parent;

    navigate(`/admin/${parentUrl}/${url}`);
  };
  return (
    <Menu
      onClick={onClick}
      style={{
        width: 200,
      }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
      items={array.map((_) => {
        if (_.children?.length) {
          return getItem(
            _.label,
            _.key,
            _.icon,
            _.children?.map((__) => getItem(__.label, __.key, __.icon))
          );
        }
        return getItem(_.label, _.key, _.icon);
      })}
    />
  );
};
export default SideBar;
