import { useState } from "react";
import { AudioOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Space, Button, Card, Table } from "antd";
import styled from "styled-components";
const { Search } = Input;

const onSearch = (value) => console.log(value);
const Logs = () => {
  const [size, setSize] = useState("default");

  const columns = [
    {
      title: "agent",
      width: 40,
      dataIndex: "agent",
      key: "name",
      fixed: "left",
    },
    {
      title: "date",
      width: 40,
      dataIndex: "date",
      key: "register",
    },
    {
      title: "description",
      width: 60,
      dataIndex: "description",
      key: "1",
    },
  ];
  const data = [
    {
      key: "1",
      agent: "admin@gmail.com",
      date: "22/11/14 - 16:32:06",
      description: "switched wg for newAcc which expired",
    },
    {
      key: "2",
      agent: "admin@gmail.com",
      date: "22/11/14 - 16:32:06",
      description: "switched wg for newAcc which expired",
    },
    {
      key: "3",
      agent: "admin@gmail.com",
      date: "22/11/14 - 16:32:06",
      description: "switched wg for newAcc which expired",
    },
    {
      key: "4",
      agent: "admin@gmail.com",
      date: "22/11/14 - 16:32:06",
      description: "switched wg for newAcc which expired",
    },
    {
      key: "5",
      agent: "admin@gmail.com",
      date: "22/11/14 - 16:32:06",
      description: "switched wg for newAcc which expired",
    },
  ];

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const TopD = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: -17px;
  `;
  return (
    <div className="site-card-border-less-wrapper">
      <TopD>
        <p>log list : 5</p>
      </TopD>
      <Card style={{ border: "none" }}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            y: 285,
          }}
          bordered
        />
      </Card>
    </div>
  );
};

export default Logs;
