import React, { Component, Fragment } from "react";
import { useContext } from "react";
import { Route, Routes, Navigate, ConfigProvider, Outlet } from "react-router-dom";
import { AuthContext } from "../Provider/AuthProvider";
import Login from "./Login";
import Page from "./Page";
import Dashboard from "./Dashboard";
import Ikev from "./Protocols/Ikev";
import Ipsec from "./Protocols/Ipsec";
import Wireguard from "./Protocols/Wireguard";
import Openvpn from "./Protocols/Openvpn";
import Users from "./Users";
import Billing from "./Billing";
import Logs from "./Logs";
import SubAgent from "./Reseller/SubAgent";
import TransferHistory from "./Reseller/TransferHistory";
import Servers from "./Servers";
import LogOut from "./LogOut";
import LayoutPage from "../Component/LayoutPage";

const Router = () => {
  const { loginState } = useContext(AuthContext);

  const AdminPrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (loginState === "verified" ? <Component {...props} /> : <Navigate to="/login" replace />)} />
  );

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin/*" element={<LayoutPage />} />
      <Route path="/admin/logout" element={<Navigate to="/login" replace />} />
      {/* <Route path="/admin/logout"  element={<Login />} /> */}
    </Routes>
  );
};

export default Router;
