import { useState } from "react";
import { AudioOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Space, Button, Card, Table } from "antd";
import IkevDrawer from "../IkevDrawer";
import styled from "styled-components";
const { Search } = Input;

const onSearch = (value) => console.log(value);
const Openvpn = () => {
  const [size, setSize] = useState("default");

  const columns = [
    {
      title: "name",
      width: 90,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "register",
      width: 50,
      dataIndex: "register",
      key: "register",
      sorter: true,
    },
    {
      title: "server code",
      width: 60,
      dataIndex: "server_code",
      key: "1",
    },
    {
      title: "expire",
      width: 60,
      dataIndex: "expire",
      key: "2",
    },
    {
      title: "remain",
      width: 100,
      dataIndex: "remain",
      key: "3",
    },
    {
      title: "connection",
      width: 60,
      dataIndex: "connection",
      key: "4",
    },
    {
      title: "action",
      width: 80,
      dataIndex: "action",
      key: "5",
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      register: "22/10/16 - 12:17:35",
      server_code: 183038,
      expire: <Button type="primary">Extend</Button>,
      user: (
        <Button type="primary" danger icon={<PlusOutlined />} size={size}>
          Assign To User
        </Button>
      ),
      connection: <Button type="primary">openvpn</Button>,
      action: (
        <Button type="primary" danger>
          Delete
        </Button>
      ),
    },
    {
      key: "1",
      name: "John Brown",
      register: "22/10/16 - 12:17:35",
      server_code: 183038,
      expire: <Button type="primary">Extend</Button>,
      user: (
        <Button type="primary" danger icon={<PlusOutlined />} size={size}>
          Assign To User
        </Button>
      ),
      connection: <Button type="primary">openvpn</Button>,
      action: (
        <Button type="primary" danger>
          Delete
        </Button>
      ),
    },
    {
      key: "1",
      name: "John Brown",
      register: "22/10/16 - 12:17:35",
      server_code: 183038,
      expire: <Button type="primary">Extend</Button>,
      user: (
        <Button type="primary" danger icon={<PlusOutlined />} size={size}>
          Assign To User
        </Button>
      ),
      connection: <Button type="primary">openvpn</Button>,
      action: (
        <Button type="primary" danger>
          Delete
        </Button>
      ),
    },
    {
      key: "1",
      name: "John Brown",
      register: "22/10/16 - 12:17:35",
      server_code: 183038,
      expire: <Button type="primary">Extend</Button>,
      user: (
        <Button type="primary" danger icon={<PlusOutlined />} size={size}>
          Assign To User
        </Button>
      ),
      connection: <Button type="primary">openvpn</Button>,
      action: (
        <Button type="primary" danger>
          Delete
        </Button>
      ),
    },
    {
      key: "1",
      name: "John Brown",
      register: "22/10/16 - 12:17:35",
      server_code: 183038,
      expire: <Button type="primary">Extend</Button>,
      user: (
        <Button type="primary" danger icon={<PlusOutlined />} size={size}>
          Assign To User
        </Button>
      ),
      connection: <Button type="primary">openvpn</Button>,
      action: (
        <Button type="primary" danger>
          Delete
        </Button>
      ),
    },
  ];

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const TopD = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: -17px;
  `;
  return (
    <div className="site-card-border-less-wrapper">
      <TopD>
        <p>openvpn : 5</p>
        <Space direction="vertical">
          <Search
            placeholder="name or link..."
            onSearch={onSearch}
            style={{
              width: 200,
            }}
          />
        </Space>
        <IkevDrawer />
      </TopD>
      <Card style={{ border: "none" }}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            y: 285,
          }}
          bordered
        />
      </Card>
    </div>
  );
};

export default Openvpn;
